import { object, string } from 'yup';
import { withStyles } from 'tss-react/mui';
import { tz, cet } from 'timezone';
import { connect } from 'react-redux';
import React, { useState, Fragment } from 'react';
import { Button, Grid, DialogActions, DialogContent, Typography, } from '@mui/material';

import { Modal, ErrorDialog, Datepicker, DatepickerContainer } from 'ui';
import { fileDownload, flatValidationErrors } from 'lib';
import { ErrorType } from 'features/common';

import { ReportType } from '../models';
import { getUsedFilter } from '../helpers';
import { getReport, getAccountStatementReport } from '../effects';

export const getDateTimeFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(new Date(date)), '%m.%d.%Y %H:%M', 'Europe/London');
};

const styles = theme => ({
  root: {
    height: 'auto',
  },
  title: {
    fontSize: '24px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  formControl: {
    width: '100%',
    marginRight: '10px',
    marginBottom: '10px',
  },
  filterItem: {
    minWidth: '120px',
    marginRight: '20px',
  },
});

let schema = object().shape({
  date: string().nullable(),
});

const mapDispatchToProps = dispatch => ({
  getAccountsReport: query => dispatch(getReport, query),
  getStatementReport: query => dispatch(getAccountStatementReport, query),
});

const AccountsDateReportFormView = ({
  classes,
  open,
  handleClose,
  readOnly,
  type,
  reportName,
  filter,
  usedFilters = null,
  title,
  getStatementReport,
  getAccountsReport,
  accountId,
}) => {

  const [ date, setDate ] = useState('');
  const { FRONT_VALID_ERR } = ErrorType;
  const [ validationErrors, setValidationErrors ] = useState({});
  const [ error, setError ] = useState('');
  const [ errorShow, setErrorShow ] = useState(false);

  const clear = () => {
    setDate('');
    setValidationErrors({});
  };
  const close = () => {
    clear();
    handleClose();
  };

  const submit = async (date) => {
    try {
      await schema.validate({ date }, { abortEarly: false });
      close();
      let response = '';
      setValidationErrors({});
      let query = {
        ...filter,
        reportName,
      };

      if (date !== '') {
        query.date = new Date(date).getTime() + (-1 * new Date().getTimezoneOffset() * 60 * 1000);
      }

      if (type === ReportType.Account) {
        response = await getAccountsReport(query);
        if (!response.ok) {
          setError(response.error);
          setErrorShow(true);
        } else {
          fileDownload(response.data, 'Accounts report', 'xlsx');
        }
      }
      if (type === ReportType.Statement) {
        query.AccountId = accountId;
        response = await getStatementReport(query);
        if (!response.ok) {
          setError(response.error);
          setErrorShow(true);
        } else {
          fileDownload(response.data, 'Account statement report', 'pdf');
        }
      }
    } catch (e) {
      if (e.name === FRONT_VALID_ERR) {
        setValidationErrors(flatValidationErrors(e.inner));
      }
      throw e;
    }
  };
  return (
    <Fragment>
      <Modal open={open} title={title}>
        <DialogContent className={classes.root}>
          <Datepicker
            className={classes.filterItem}
            error={!!validationErrors['date']}
            helperText={validationErrors['date']}
            id="status-date"
            label="Status date"
            popperContainer={DatepickerContainer}
            value={date}
            onChange={setDate}
          />
          <Grid style={{ marginTop: '15px' }}>
            {usedFilters ? <>
              <Typography>Filters: </Typography>
              {usedFilters.map((field, index) =>
                <Typography key={index}>
                  {getUsedFilter(field, filter)}
                </Typography>
              )}
            </> : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Button color="primary" onClick={close}>
              Close
            </Button>
            <Button color="primary" disabled={readOnly} onClick={() => submit(date)}>
              Create
            </Button>
          </Grid>
        </DialogActions>
      </Modal>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
    </Fragment>
  );
};

export const AccountsDateReportForm = connect(null, mapDispatchToProps)(withStyles(AccountsDateReportFormView, styles));
